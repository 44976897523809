

export default {
  data: () => ({
    icons: [
      'mdi-home',
      'mdi-email',
      'mdi-calendar',
      'mdi-delete',
    ],
    // links: [
    //   'Home',
    //   'About Us',
    //   'Team',
    //   'Services',
    //   // 'Blog',
    //   'Contact Us',
    // ],
    links: [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "About Us",
        link: "/about-us",
      },
      {
        text: "Services",
        link: "/services",
      },
    ],
    padless: false,
    variant: 'default',
  }),
}

